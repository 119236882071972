window.addEventListener("load", function () {
  var element = document.getElementsByClassName("siema");
  if (typeof element[0] != "undefined" && element[0] != null) {
    const heroSiema = new Siema({
      selector: ".siema",
      loop: true,
      duration: 600,
      easing: "cubic-bezier(.17,.67,.32,1.34)",
      onInit: () => {
        onInitCallback(element[0]);
      },
    });
    const heroPrev = document.querySelector(".heroPrev");
    const heroNext = document.querySelector(".heroNext");

    heroPrev.addEventListener("click", () => heroSiema.prev());
    heroNext.addEventListener("click", () => heroSiema.next());
  }

  var element = document.getElementsByClassName("siemaNews");
  if (typeof element[0] != "undefined" && element[0] != null) {
    const sliderItems = element[0].children.length;
    const siemaNews = new Siema({
      selector: ".siemaNews",
      perPage: {
        0: 2,
        768: 3,
        1024: 4,
      },
      loop: sliderItems > 4 ? true : false,
      duration: 400,
      easing: "cubic-bezier(.17,.67,.32,1.34)",
      onInit: () => {
        onInitCallback(element[0]);
      },
    });

    const newsPrev = document.querySelector(".newsPrev");
    const newsNext = document.querySelector(".newsNext");

    newsPrev.addEventListener("click", () => siemaNews.prev());
    newsNext.addEventListener("click", () => siemaNews.next());
  }

  var element = document.getElementsByClassName("siemaTop");
  if (typeof element[0] != "undefined" && element[0] != null) {
    const sliderItems = element[0].children.length;
    const siemaTop = new Siema({
      selector: ".siemaTop",
      perPage: {
        0: 2,
        768: 3,
        1024: 4,
      },
      loop: sliderItems > 4 ? true : false,
      duration: 400,
      easing: "cubic-bezier(.17,.67,.32,1.34)",
      onInit: () => {
        onInitCallback(element[0]);
      },
    });

    const topPrev = document.querySelector(".topPrev");
    const topNext = document.querySelector(".topNext");

    topPrev.addEventListener("click", () => siemaTop.prev());
    topNext.addEventListener("click", () => siemaTop.next());
  }

  var element = document.getElementsByClassName("siemaSales");
  if (typeof element[0] != "undefined" && element[0] != null) {
    const sliderItems = element[0].children.length;
    const siemaSales = new Siema({
      selector: ".siemaSales",
      perPage: {
        0: 2,
        768: 3,
        1024: 4,
      },
      loop: sliderItems > 4 ? true : false,
      duration: 400,
      easing: "cubic-bezier(.17,.67,.32,1.34)",
      onInit: () => {
        onInitCallback(element[0]);
      },
    });

    const salesPrev = document.querySelector(".salesPrev");
    const salesNext = document.querySelector(".salesNext");

    salesPrev.addEventListener("click", () => siemaSales.prev());
    salesNext.addEventListener("click", () => siemaSales.next());
  }

  var items = document.getElementsByClassName("same-height");
  if (typeof items[0] != "undefined" && items[0] != null) {
    for (let i = 0; i < items.length; i++) {
      var height = 0;
      for (let j = 0; j < items[i].children[0].children.length; j++) {
        if (items[i].children[0].children[j].offsetHeight > height) {
          height = items[i].children[0].children[j].offsetHeight;
        }
      }
      for (let j = 0; j < items[i].children[0].children.length; j++) {
        items[i].children[0].children[j].style.height = height + "px";
      }
    }
  }

  function onInitCallback(element) {
    element.parentNode.classList.remove("loading-box");
    element.classList.add("ready");
  }
});

(function ($) {
  var resizeTimer;

  $(window).on("resize", function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      var items = document.getElementsByClassName("same-height");
      if (typeof items[0] != "undefined" && items[0] != null) {
        for (let i = 0; i < items.length; i++) {
          var height = 0;
          for (let j = 0; j < items[i].children[0].children.length; j++) {
            if (items[i].children[0].children[j].offsetHeight > height) {
              height = items[i].children[0].children[j].offsetHeight;
            }
          }
          for (let j = 0; j < items[i].children[0].children.length; j++) {
            items[i].children[0].children[j].style.height = height + "px";
          }
        }
      }
    }, 50);
  });


    // Sending emails
    var form = $('#ajax-contact');
    var formMessages = $('#form-messages');
    // Set up an event listener for the contact form.
    $(form).submit(function(e) {

            e.preventDefault();
            $(formMessages).text('response');
            var formData = $(form).serialize();
            var data = {
                action: "send_contact_mail",
                name: form.find("input[name=name]").val(),
                email: form.find("input[name=email]").val(),
                message: form.find("textarea[name=message]").val(),
                phone: form.find("input[name=phone]").val(),
                service: form.find("input[name=quantserviceity]").val(),
              };

            // Submit the form using AJAX.
            $.ajax({
                type: 'POST',
                url: my_ajax_object.ajax_url,
                data: data,
            })
            .done(function(response) {
                console.log('test 3');
            // Make sure that the formMessages div has the 'success' class.
            $(formMessages).removeClass('error');
            $(formMessages).addClass('success');
            // Set the message text.
            $(formMessages).text(response);

            // Clear the form.
            $('input[name="name"]').val('');
            $('input[name="email"]').val('');
            $('input[name="phone-number"]').val('');
            $('textarea').val('');
            })
            .fail(function(data) {
            // Make sure that the formMessages div has the 'error' class.
            $(formMessages).removeClass('success');
            $(formMessages).addClass('error');
            // Set the message text.
            if (data.responseText !== '') {
                $(formMessages).text(data.responseText);
            } else {
                $(formMessages).text('Oops! An error occured and your message could not be sent.');
            }
            });
            e.preventDefault();
    });



  //Sidebar 1

  $(document).on("click", "#sidebar1 ul li p", function (e) {
    var submenu = $(this)
      .parent()
      .parent()
      .children("li")
      .eq(1)
      .children()
      .eq(0);
    let status = 0;
    if (submenu.is(":visible")) {
      status = 1;
    }

    if (status == 1) {
      submenu.show();
    } else {
      submenu.hide();
    }
    submenu.toggle();
  });

  $(document).on("click", function (event) {
    var $trigger = $("#sidebar1 ul li");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      var title = $("#sidebar1 ul li p");
      title.parent().parent().children("li").eq(1).children().eq(0).hide();
    }
  });

  //Sidebar 2

  $(document).on("click", "#sidebar2 ul li p", function (e) {
    var submenu = $(this).parent().find("ul");
    let status = 0;
    if (submenu.is(":visible")) {
      status = 1;
    }
    $("#sidebar2 ul li ul").hide();
    $(".widgettitle").removeClass("active");
    if (status == 1) {
      submenu.show();
      $(this).addClass("active");
    } else {
      submenu.hide();
      $(this).removeClass("active");
    }
    submenu.toggle();
    $(this).toggleClass("active");
  });

  $(document).on("click", function (event) {
    var $trigger = $("#sidebar2 ul li");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      $("#sidebar2 ul li ul").hide();
      eraseCookie("showFilter");
      $(".widgettitle").removeClass("active");
    }
  });

  $(document).on("click", "#sidebar2 a", function (e) {
    setCookie("showFilter", this.parentNode.parentNode.parentNode.id, 2);
  });
  if (getCookie("showFilter")) {
    var submenu = document.getElementById(getCookie("showFilter"));
    if (typeof submenu != "undefined" && submenu != null) {
      $(submenu).find("ul").show();
      $(submenu).find("p").addClass("notransition");
      $(submenu).find("p").addClass("active");
      $(submenu).find("p").removeClass("notransition");
    }
  }
  // $(document).on("click", "#sidebar2 a", function (e) {
  //   e.preventDefault();
  //   console.log(this.href);
  //   window.history.pushState("", "", this.href);

  //   $.ajax({
  //     type: "post",
  //     url: wc_add_to_cart_params.ajax_url,
  //     data: {
  //       action: "refreshProductLoop",
  //       url: this.href,
  //     },
  //     success: function (response) {
  //       console.log(response);
  //       $("#products-list").html(response);
  //     },
  //   });
  // });

  $(document).on("click", ".variation_add_to_cart_button", function (e) {
    e.preventDefault();
    var $thisbutton = $(this),
        form = $thisbutton.closest("form.cart"),
        id = $thisbutton.val(),
        product_qty = form.find("input[name=quantity]").val() || 1,
        product_id = form.find("input[name=product_id]").val() || id,
        variation_id = form.find("input[name=variation_id]:checked").val() || 0,
        respones_div = $thisbutton
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div.respones");

    var data = {
        action: "woocommerce_ajax_add_to_cart",
        product_id: product_id,
        product_sku: "",
        quantity: product_qty,
        variation_id: variation_id,
      };

    $.ajax({
        type: "post",
        url: wc_add_to_cart_params.ajax_url,
        data: data,
        beforeSend: function (response) {
            $thisbutton.removeClass("added").addClass("loading");
            respones_div.html("<p class='adding_loading'>Dodaję</p>");
            respones_div.removeClass("hidden");
            respones_div.addClass("flex");
        },
        complete: function (response) {},
        success: function (response) {
            $thisbutton.addClass("added").removeClass("loading");
        if (response.error && response.product_url) {
          //window.location = response.product_url;
          respones_div.html("<p class='text-red-500'>Błąd dodawania</p>");
          return;
        } else {
          respones_div.html(
            "<p class='text-green-500'>Produkt dodany</p> <a href='/zamowienie' class='underline text-right font-light'>Zobacz koszyk</a>"
          );
          form.find("input[name=quantity]").val(1);
          setTimeout(function () {
            respones_div.removeClass("flex");
            respones_div.addClass("hidden");
            respones_div.html("");
            $thisbutton.removeClass("added");
          }, 5000);
          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart_icon",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart-icon").html(response);
            });
          })(jQuery);

          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart").html(response);
            });
          })(jQuery);

          $(document.body).trigger("added_to_cart", [
            response.fragments,
            response.cart_hash,
            $thisbutton,
          ]);
        }
        }
    });
    return false;
  });

  $(document).on("click", ".qtn-input-minus", function (e) {
    e.preventDefault();
    let input = $(this).parent().find("input");
    let num = +input.val() - 1;
    if (num <= 1) {
      input.val(1);
    } else {
      input.val(num);
    }
  });

  $(document).on("click", ".qtn-input-plus", function (e) {
    e.preventDefault();
    let input = $(this).parent().find("input");
    let num = +input.val() + 1;
    input.val(num);
  });



  $(document).on("click", ".simple_add_to_cart_button", function (e) {
    e.preventDefault();

    var $thisbutton = $(this),
      form = $thisbutton.closest("form.cart"),
      respones_div = $thisbutton
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div.respones"),
      id = $thisbutton.val(),
      product_qty = form.find("input[name=quantity]").val() || 1,
      product_id = form.find("input[name=product_id]").val() || id;

      var data = {
        action: "woocommerce_ajax_add_to_cart_simple",
        product_id: product_id,
        product_sku: "",
        quantity: product_qty,
      };

    $(document.body).trigger("adding_to_cart", [$thisbutton, data]);
    $.ajax({
      type: "post",
      url: wc_add_to_cart_params.ajax_url,
      data: data,
      beforeSend: function (response) {
        $thisbutton.removeClass("added").addClass("loading");
        respones_div.html("<p class='adding_loading'>Dodaję</p>");
        respones_div.removeClass("hidden");
        respones_div.addClass("flex");
      },
      complete: function (response) {},
      success: function (response) {
        $thisbutton.addClass("added").removeClass("loading");
        if (response.error && response.product_url) {
          //window.location = response.product_url;
          respones_div.html("<p class='text-red-500'>Błąd dodawania</p>");
          return;
        } else {
          respones_div.html(
            "<p class='text-green-500'>Produkt dodany</p> <a href='/zamowienie' class='underline text-right font-light'>Zobacz koszyk</a>"
          );
          setTimeout(function () {
            respones_div.removeClass("flex");
            respones_div.addClass("hidden");
            respones_div.html("");
            $thisbutton.removeClass("added");
          }, 5000);

          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart_icon",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart-icon").html(response);
            });
          })(jQuery);

          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart").html(response);
            });
          })(jQuery);

          $(document.body).trigger("added_to_cart", [
            response.fragments,
            response.cart_hash,
            $thisbutton,
          ]);
        }
      },
    });
    return false;
  });

  $(document).on("click", ".single_add_to_cart_button", function (e) {
    e.preventDefault();

    var $thisbutton = $(this),
      form = $thisbutton.closest("form.cart"),
      respones_div = $thisbutton
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div.respones"),
      id = $thisbutton.val(),
      product_qty = form.find("input[name=quantity]").val() || 1,
      product_id = form.find("input[name=product_id]").val() || id,
      variation_id = form.find("input[name=variation_id]").val() || 0;

    var data = {
      action: "woocommerce_ajax_add_to_cart",
      product_id: product_id,
      product_sku: "",
      quantity: product_qty,
      variation_id: variation_id,
    };

    $(document.body).trigger("adding_to_cart", [$thisbutton, data]);
    $.ajax({
      type: "post",
      url: wc_add_to_cart_params.ajax_url,
      data: data,
      beforeSend: function (response) {
        $thisbutton.removeClass("added").addClass("loading");
        respones_div.html("<p class='adding_loading'>Dodaję</p>");
        respones_div.removeClass("hidden");
        respones_div.addClass("flex");
      },
      complete: function (response) {},
      success: function (response) {
        $thisbutton.addClass("added").removeClass("loading");
        if (response.error && response.product_url) {
          //window.location = response.product_url;
          respones_div.html("<p class='text-red-500'>Błąd dodawania</p>");
          return;
        } else {
          respones_div.html(
            "<p class='text-green-500'>Produkt dodany</p> <a href='/zamowienie' class='underline text-right font-light'>Zobacz koszyk</a>"
          );
          setTimeout(function () {
            respones_div.removeClass("flex");
            respones_div.addClass("hidden");
            respones_div.html("");
            $thisbutton.removeClass("added");
          }, 5000);
          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart_icon",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart-icon").html(response);
            });
          })(jQuery);

          (function ($) {
            "use strict";
            var data = {
              action: "mode_theme_update_mini_cart",
            };
            $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
              $("#mode-mini-cart").html(response);
            });
          })(jQuery);

          $(document.body).trigger("added_to_cart", [
            response.fragments,
            response.cart_hash,
            $thisbutton,
          ]);
        }
      },
    });
    return false;
  });

  $("#menu-menu-mobile li.menu-item-has-children a")
    .not("#menu-menu-mobile li.menu-item-has-children ul.sub-menu li a")
    .on("click", function (event) {
      if (event.target !== this) {
        return;
      } else {
        event.preventDefault();
        $(this).parent().find("ul.sub-menu").toggle();
        $(this).parent().toggleClass("open");
      }
    });

  $("#menu-menu-mobile li.menu-item-has-children").on(
    "click",
    function (event) {
      if (event.target !== this) {
        return;
      } else {
        $(this).find("ul.sub-menu").toggle();
        $(this).toggleClass("open");
      }
    }
  );

  $("#search-clear").on("click", function () {
    var searchInput = document.getElementById("searchFormMenu");
    if (typeof searchInput != "undefined" && searchInput != null) {
      var searchDropdown = document.getElementById("search-dropdownMenu");
      var searchDropdownContainer = document.getElementById(
        "search-dropdownMenu-container"
      );
      var searchClear = document.getElementById("search-clear");
      searchInput.value = "";
      searchDropdownContainer.innerHTML = "";
      searchDropdown.style.display = "none";
      searchClear.style.display = "none";
    }
  });
  $("#searchListedOffer").keyup(
    delay(function (e) {
        if ($(this).val().length < 2) {
            var searchDropdown = document.getElementById("search-listedoffers");
            var allOffers = document.getElementById("all-listedoffers");
            searchDropdown.style.display = "none";
            allOffers.style.display = "block";
          } else{
            var allOffers = document.getElementById("all-listedoffers");
            allOffers.style.display = "none";
            var search_input_value = $(this).val();
            jQuery.ajax({
              url: my_ajax_object.ajax_url,
              type: "post",
              async: false,
              data: {
                action: "dynamicSearchListedOffer",
                search: search_input_value,
              },
              success: function (response) {
                response = $.parseJSON(response);
                var searchDropdown = document.getElementById("search-listedoffers");
                searchDropdown.innerHTML = "";

                if( typeof response.offers !== "undefined"){
                    var container = document.createElement("div");
                    container.classList.add(
                        "w-full",
                        "flex",
                        "flex-col",
                        "mb-12",
                        "mt-4",
                        "space-y-6"
                    );
                    $.each(response.offers, function (key, value) {
                        container.innerHTML += value;
                    });
                    searchDropdown.appendChild(container);
                }
                searchDropdown.style.display = "block";
              }
            });
          }
    }, 150)
  );




  $("#searchFormMenu").keyup(
    delay(function (e) {
      var searchClear = document.getElementById("search-clear");
      if ($(this).val().length < 1) {
        searchClear.style.display = "none";
      } else {
        searchClear.style.display = "block";
      }

      if ($(this).val().length < 2) {
        var searchDropdown = document.getElementById("search-dropdownMenu");
        var searchDropdownContainer = document.getElementById(
          "search-dropdownMenu-container"
        );
        searchDropdownContainer.innerHTML = "";
        searchDropdown.style.display = "none";
      } else {
        var search_input_value = $(this).val();
        jQuery.ajax({
          url: my_ajax_object.ajax_url,
          type: "post",
          async: false,
          data: {
            action: "dynamicSearchMenu",
            search: search_input_value,
          },
          success: function (response) {
            response = $.parseJSON(response);
            $("#searchOptionsMobile").html("");
            var searchDropdown = document.getElementById("search-dropdownMenu");
            var searchDropdownContainer = document.getElementById(
              "search-dropdownMenu-container"
            );

            searchDropdownContainer.innerHTML = "";


            if( typeof response.services !== "undefined"){
                var elem = document.createElement("div");
                elem.classList.add(
                    "w-full",
                    "mt-4",
                    "mb-2",
                    "text-sm",
                    "text-gray-700"
                );
                elem.innerHTML += "<p>Oferta salonu</p>";
                searchDropdownContainer.appendChild(elem);
                var container = document.createElement("div");
                container.classList.add(
                    "w-full",
                    "flex",
                    "flex-wrap",
                );
                $.each(response.services, function (key, value) {
                    container.innerHTML += value;
                });
                searchDropdownContainer.appendChild(container);
            }

            if (typeof response.categories !== "undefined") {
              var elem = document.createElement("div");
              elem.classList.add(
                "w-full",
                "mt-4",
                "mb-2",
                "text-sm",
                "text-gray-700"
              );
              elem.innerHTML = "<p>Kategorie</p>";
              searchDropdownContainer.appendChild(elem);
              $.each(response.categories.names, function (key, value) {
                var elem = document.createElement("div");
                let link = response.categories.link[key];
                elem.innerHTML =
                  "<div class=''> <a class='font-normal text-sm p-2 w-full mb-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900' href='" +
                  link +
                  "' title='" +
                  value +
                  "'>" +
                  value +
                  "</a></div>";
                searchDropdownContainer.appendChild(elem);
              });
            }

            if( typeof response.products !== "undefined"){
                var elem = document.createElement("div");
                elem.classList.add(
                    "w-full",
                    "mt-4",
                    "mb-2",
                    "text-sm",
                    "text-gray-700"
                );
                elem.innerHTML += "<p>Produkty</p>";
                searchDropdownContainer.appendChild(elem);
                var container = document.createElement("div");
                container.classList.add(
                    "w-full",
                    "flex",
                    "-px-4",
                    "lg:-px-8"
                );
                $.each(response.products, function (key, value) {
                    container.innerHTML += value;
                });
                searchDropdownContainer.appendChild(container);
            }

            if( typeof response.courses !== "undefined"){
                var elem = document.createElement("div");
                elem.classList.add(
                    "w-full",
                    "mt-2",
                    "mb-2",
                    "text-sm",
                    "text-gray-700"
                );
                elem.innerHTML += "<p>Kursy</p>";
                searchDropdownContainer.appendChild(elem);
                var container = document.createElement("div");
                container.classList.add(
                    "w-full",
                    "flex",
                );
                $.each(response.courses, function (key, value) {
                    container.innerHTML += value;
                });
                searchDropdownContainer.appendChild(container);
            }

            if( typeof response.posts !== "undefined"){
                var elem = document.createElement("div");
                elem.classList.add(
                    "w-full",
                    "mt-2",
                    "mb-2",
                    "text-sm",
                    "text-gray-700"
                );
                elem.innerHTML += "<p>Aktualności</p>";
                searchDropdownContainer.appendChild(elem);
                var container = document.createElement("div");
                container.classList.add(
                    "w-full",
                    "grid",
                    "grid-cols-4",
                    "gap-12",
                );
                $.each(response.posts, function (key, value) {
                    container.innerHTML += value;
                });
                searchDropdownContainer.appendChild(container);
            }

            searchDropdown.style.display = "block";
          },
        });
      }
    }, 300)
  );

  $('.portfolio-slider').slick({
    slidesToShow: 3,
    infinite: true,
    dots: false,
    draggable: true,
    responsive: [{ breakpoint: 767, settings: { slidesToShow: 3, touchMove: true, centerMode: true, centerPadding: 0, swipe: true } }],
  });

  $('.teamSlider').slick({
    arrows: true,
    nextArrow: '<button class="slick-next slick-arrow hover:bg-laurell-light flex justify-center items-center transition rounded"><svg class="w-10" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M10.75 8.75L14.25 12L10.75 15.25"></path></svg></button>',
    prevArrow: '<button class="slick-prev slick-arrow hover:bg-laurell-light flex justify-center items-center transition rounded"><svg class="w-10" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13.25 8.75L9.75 12L13.25 15.25"></path></svg></button>',
    dots: false,
    slidesToShow: 1,
    mobileFirst: true,
    infinite: true,
    draggable: true,
    responsive: [{ breakpoint: 767, settings: { slidesToShow: 3, touchMove: true, centerMode: true, centerPadding: 0, swipe: true } }],
});


  /** Checkout */
  $(document.body).on("change", "input[name='payment_method']", function () {
      if (this.value == 'przelewy24') {
          $('button#place_order').html('Przejdź do wyboru metody płatności');
      }
      else {
        $('button#place_order').html('Kupuję i płacę');
      }
  });

  $(document.body).on("change", "input.cart_input_quantity", function () {
    var product_qty = $(this).val();
    var cart_item_key = $(this).attr("name");

    var data = {
      action: "woocommerce_ajax_update_cart",
      quantity: product_qty,
      cart_item_key: cart_item_key,
    };

    $.ajax({
      type: "post",
      url: wc_add_to_cart_params.ajax_url,
      data: data,
      beforeSend: function (response) {
      },
      complete: function (response) {},
      success: function (response) {
        if (response.error) {
          return;
        } else {
          var data = {
            action: "mode_theme_update_checkout_sidebar_review",
          };
          $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
            $("#sidebar-review").html(response);
          });
          $( document.body ).trigger( 'update_checkout' );
        }
      },
    });
  });

  $("button.cart-qtn-input-plus").click(function (e) {
    e.preventDefault();

    let input = $(this).parent().find("input.cart_input_quantity");
    let num = +input.val() + 1;
    input.val(num);

    var product_qty = num;
    var cart_item_key = input.attr("name");

    var data = {
      action: "woocommerce_ajax_update_cart",
      quantity: product_qty,
      cart_item_key: cart_item_key,
    };

    $.ajax({
      type: "post",
      url: wc_add_to_cart_params.ajax_url,
      data: data,
      beforeSend: function (response) {
      },
      complete: function (response) {},
      success: function (response) {
        if (response.error) {
          return;
        } else {
          let price = input
            .parent()
            .parent()
            .find(".cart_item_subtotal")
            .data("price");

          let total_price = price * product_qty;

          total_price = total_price.toFixed(2).toString().replace(".", ",");

          input
            .parent()
            .parent()
            .find(".cart_item_subtotal")
            .html(total_price + " zł");
          var data = {
            action: "mode_theme_update_checkout_sidebar_review",
          };
          $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
            $("#sidebar-review").html(response);
          });
          $( document.body ).trigger( 'update_checkout' );
        }
      },
    });
  });

  $("button.cart-qtn-input-minus").click(function (e) {
    e.preventDefault();

    let input = $(this).parent().find("input.cart_input_quantity");
    let num = +input.val() - 1;
    if (num <= 1) {
      input.val(1);
      num = 1;
    } else {
      input.val(num);
    }

    var product_qty = num;
    var cart_item_key = input.attr("name");

    var data = {
      action: "woocommerce_ajax_update_cart",
      quantity: product_qty,
      cart_item_key: cart_item_key,
    };

    $.ajax({
      type: "post",
      url: wc_add_to_cart_params.ajax_url,
      data: data,
      beforeSend: function (response) {
      },
      complete: function (response) {},
      success: function (response) {
        if (response.error) {
          return;
        } else {
          let price = input
            .parent()
            .parent()
            .find(".cart_item_subtotal")
            .data("price");

          let total_price = price * product_qty;

          total_price = total_price.toFixed(2).toString().replace(".", ",");

          input
            .parent()
            .parent()
            .find(".cart_item_subtotal")
            .html(total_price + " zł");
          var data = {
            action: "mode_theme_update_checkout_sidebar_review",
          };
          $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
            $("#sidebar-review").html(response);
          });
          $( document.body ).trigger( 'update_checkout' );
        }
      },
    });
  });

  $(document.body).on("updated_checkout", function () {
    (function ($) {
      "use strict";
      var data = {
        action: "mode_theme_update_checkout_sidebar_review",
      };
      $.post(wc_add_to_cart_params.ajax_url, data, function (response) {
        $("#sidebar-review").html(response);
      });
    })(jQuery);

    if( $("input[name='payment_method']:checked").val() == 'przelewy24' ) {
        $('button#place_order').html('Przejdź do wyboru metody płatności');
    }
    else {
      $('button#place_order').html('Kupuję i płacę');
    }

  });

  $(document.body).on("init_checkout", function () {
    $(".checkout-input input").each(function () {
      if (!$(this).val()) {
        $(this).parent().parent().addClass("is-active");
      } else {
        $(this).parent().parent().removeClass("is-active");
      }
    });
  });

  $(".checkout-input input")
    .focus(function () {
      $(this).parent().parent().removeClass("is-active");
    })
    .blur(function () {
      if (!$(this).val()) {
        $(this).parent().parent().addClass("is-active");
      }
    });



  /** End checkout */



  $(document.body).on("removed_from_cart", function () {
    console.log($(this));
  });




})(jQuery);

function delay(callback, ms) {
  var timer = 0;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}


var accepted = getCookie("accepted");
if (accepted == "" || accepted == null) {
    document.getElementById('cookies').style.display = 'flex';
}


window.smoothScroll = function(target) {
    var scrollContainer = target;
    do { //find scroll container
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
        if (target == scrollContainer) break;
        targetY += target.offsetTop-50;
    } while (target = target.offsetParent);

    scroll = function(c, a, b, i) {
        i++; if (i > 25) return;
        c.scrollTop = a + (b - a) / 25 * i;
        setTimeout(function(){ scroll(c, a, b, i); }, 25);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
}
